<template>
  <win-block>
    <template #win-title-left="{ active }">Окна {{ active }}</template>
    <v-btn large
           @click="dialog_example=true"
    >
      Открыть диалог
    </v-btn>

    <dialog-simple v-model="dialog_example" :data="data_example" @closeOK="projectChange($event)"/>


  </win-block>
</template>

<script>

export default {


  data: () => ({
    dialog_example: false,
    data_example:{'value1':1, 'value2':2, 'value3':3}

  }),

  components: {
    "win-block": () => import('@/site/componets/winblock'),
    "dialog-simple": () => import("@/site/modules/test/examples/dialog-simple"),
  },

}
</script>
